<template>
  <div class="AvatarVsAvatar">
    <div
      class="Avatar"
      :style="{ backgroundImage: `url(${me.avatar})` }" />
    <div class="Avatar" />
    <div class="vs">
      Vs
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        me() {
            return {
                avatar: this.$store.state.moduleAuth.profile.avatar
            };
        },
        you() {
            return {
                avatar: ''
            };
        }
    }
};
</script>

<style scoped lang="scss">
.AvatarVsAvatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Avatar {
    $size: 4.5em;
    width: $size;
    height: $size;
    background-color: gray;
    border-radius: 50%;

    margin: 0 0.5em;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.vs {
    $size: 2.5em;
    position: absolute;
    width: $size;
    height: $size;
    background-color: blue;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
